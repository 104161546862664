<template>
  <div class="aboutOurCompany">
    <div class="title"><h2>الخط الساخن</h2></div>
    <v-divider></v-divider>
    <v-container>
      <v-row>
        <h4 class="blue--text">
          تطبيق الهاتف المحمول (HCWW 125)، لتلقى شكاوى المواطنين والسداد
          الالكتروني للفواتير المتعلقة بخدمات مياه الشرب والصرف الصحى
        </h4>
      </v-row>
      <v-row class="mt-10">
        <v-col class="subClass" cols="12" sm="12" xs="12" lg="9">
          <h3>
            <p>
              نشرت الشركة القابضة لمياه الشرب والصرف الصحى، فيديو تعريفى، يوضح
              كيفية تحميل والتسجيل فى التطبيق الجديد 125 الخاص بتلقى الشكاوى
              المتعلقة بالفواتير وانقطاع المياه وخلافه. وامكانية الدفع
              الالكتروني لفواتير استهلاك مياه الشرب والصرف الصحي
            </p>
          </h3>

          <v-img src="@/assets/Images/36.png"></v-img>
          <h4>
            <p>
              ياتي ذلك تنفيذا لتوجيهات القيادة السياسية نحو التحول الرقمي
              للقطاعات المختلفة بالدولة وخاصة الخدمات التي تمس المواطنين وفي
              اطار تطوير منظومة تكنولوجيا المعلومات بالشركة القابضة وطبقا
              للاجراءات الوقائية التي تتخذها الدولة المصرية للحد من انتشار فيروس
              كورونا المستجد .
            </p>
          </h4>
          <h4>
            يتيح تطبيق الهاتف المحمول 125، عدة مزايا من أهمها، التعرف على
            المعلومات والأخبار الخاصة بقطاع مياه الشرب والصرف الصحى، وإرسال
            إشعارات للمواطنين عند تحميل التطبيق بكيفية ترشيد الاستهلاك والحفاظ
            على شبكات الصرف الصحى، وإرسال إشعارات بأعمال الصيانة المخططة وغير
            المخططة والتعرف على توقيتات انقطاع المياه لتنبيه المواطنين بتدبير
            احتياجاتهم من المياه، وتلقى شكاوى المواطنين والرد عليها، وإمكانية
            التصوير الفوتوغرافى لمكان الشكوى أو الكسر وإرساله عبر التطبيق.
          </h4>
          <v-row justify="center">
            <a @click="openApp(1)" class="linkApp"
              ><v-img
                src="@/assets/Images/37.png"
                class="mobGoogleClass"
                width="230px"
                height="80px"
            /></a>
            <a @click="openApp(2)" class="linkApp"
              ><v-img
                src="@/assets/Images/38.png"
                class="mobAppClass"
                width="230px"
                height="80px"
            /></a>
          </v-row>
          <h4>
            <p>
              كان المهندس ممدوح رسلان، رئيس مجلس إدارة الشركة القابضة لمياه
              الشرب والصرف الصحى، قد أكد أن الشركة أطلقت تطبيق الهاتف المحمول
              (HCWW 125)، لتلقى شكاوى المواطنين المتعلقة بخدمات مياه الشرب
              والصرف الصحى، سواء كسور مواسير المياه، أو طفوحات الصرف الصحى، أو
              قيمة الفواتير، حيث يقوم المواطن بتحميل التطبيق على هاتفه المحمول،
              وتسجيل حساب عليه بالبيانات الشخصية للمواطن، موضحاً أن التطبيق يتيح
              أيضاً للمواطن تلقى الأخبار بمواعيد انقطاع المياه وأعمال الصيانة
              المخططة لشبكات المياه والصرف الصحى، كما أنه جارٍ تفعيل خدمة دفع
              الفاتورة باستخدام التطبيق.
            </p>
          </h4>
          <h4>لمشاهدة الفيديو لتحميل واستخدام التطبيق</h4>
          <v-flex>
            <iframe
              justify="center"
              width="380"
              height="200"
              src="https://www.youtube.com/embed/lxPod5W8rZs"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </v-flex>
        </v-col>
        <v-col cols="12" sm="12" xs="12" lg="3">
          <Link></Link>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import Link from "../../components/link.vue";
export default {
  name: "Anelsherka",
  components: {
    Link,
  },
  data() {
    return {
      ArrayOFAnElsherka: [
        {
          icon: "mdi-arrow-right-drop-circle-outline",
          title: "رساله الشركة",
          img: require("@/assets/Images/7.png"),
        },
        {
          icon: "",
          title: "صفحتنا علي الفيسبوك",
          img: require("@/assets/Images/8.jpg"),
          subText: "صفحتنا",
        },
        {
          icon: "",
          title: "مواقع هامة",
          listofItem: [
            {
              subImportantSite: "بوابه الحكومه المصريه",
              link: "https://www.egypt.gov.eg/arabic/home.aspx",
            },
            {
              subImportantSite: "رئاسه مجلس الوزراء",
              link: "https://www.cabinet.gov.eg/Arabic/Pages/default.aspx",
            },

            {
              subImportantSite: "الشركة القابضة لمياه الشرب و الصرف الصحى",
              link: "https://www.hcww.com.eg/ar/default.aspx",
            },
          ],
        },
      ],
    };
  },
  methods: {
    openApp(num) {
      if (num == 1) {
        window.open(
          "https://apps.apple.com/eg/app/%D9%A1%D9%A2%D9%A5/id1431089961",
          "_blank"
        );
      } else if (num == 2) {
        window.open(
          "https://play.google.com/store/apps/details?id=hcww.orchtech.com.ebrd_live",
          "_blank"
        );
      }
    },
  },
};
</script>
<style >
.mobAppClass,
.mobGoogleClass {
  border-radius: 10px;
}
.linkApp {
  padding: 15px;
}
</style>
